import { CommonEnvironment } from './environment.common';

export class Environment extends CommonEnvironment {
  static contact: any = {
    email: 'kajsa@alldesign.no'
  };
  static content: any = {
    welcomeMessage:  {
      en: '',
      nb: ''
    },
    titleFrontpage: {
      en: 'Upcoming events',
      nb: 'Kommende arrangementer'
    },
    siteName: 'Visit Orkland Kalender',
    siteNameTranslatable: {
      nb: 'Visit Orkland Kalender',
      en: 'Visit Orkland Calendar'
    }
  };
  static facebookAppID: string = '1586681388380257';
  static firebase: any = {
    apiKey: "AIzaSyBGGLcm-SlqEoaiEv5YpgSV6hO-GAz5DIE",
    authDomain: "visitorkland.hvaskjerkalender.no",
    projectId: "visit-orkland",
    storageBucket: "visit-orkland.appspot.com",
    messagingSenderId: "672999742370",
    appId: "1:672999742370:web:7dba8c694647fa1ef7f0fb",
    measurementId: "G-2Q1XK33N2E"
  };
  static newsletterEnabled: boolean = false;
  static onlyForRegisteredUsers: boolean = true;
  static owner: any = {
    name: "VisitOrkland.no",
    link: 'https://www.visitorkland.no/'
  };
  static partners: any[] = [{
    logo: 'visitorkland.png',
    link: 'https://visitorkland.no/'
  }];
  static production: boolean = true;
  static registration: any = {
    allowPublic: true,
    allowLoginProviders: true
  };
  static synchro: any[] = [
    {
      destination: "visitnorway",
      name: "Visit Norway",
      baseURL: "https://www.visitnorway.no/event/hsk/{slug}/",
      optional: true,
      checked: false,
      type: "visitnorway"
    }
  ];
  static siteURL: string = 'https:/visitorkland.hvaskjerkalender.no';
  static town: any = {
    name: 'Orkland',
    hint: 'Orkland Norway',
    lat: 63.505648388611455,
    lng: 9.82610300590228
  };
  static ui: any = {
    ... CommonEnvironment.ui,
    titleNewsletter: {
      en: 'Subscribe to events from VisitOrkland.no',
      nb: 'Abonner på arrangement fra VisitOrkland.no'
    },
    showOnFooter: {
      facebook: false,
      contact: false,
      why: false,
      ads: false,
      contactExternalLink: "https://www.visitorkland.no/kontakt",
      organizers: false,
      privacy: true,
      what: true
    },
    showWelcomeMessage: false
  };
};

export const environment: any = Environment;
